import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"

const BackToTopContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  
  @media (max-width: 1024px) {
    display: none;
  }
`

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const _toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    document.addEventListener("scroll", _toggleVisibility)

    return () => {
      document.removeEventListener("scroll", _toggleVisibility)
    }
  }, [])

  return null
  //if (!isVisible) return null
  return (
    <BackToTopContainer>
      <Button
        variant="outline-danger"
        className="fab-button"
        onClick={_scrollToTop}
      >
        &#8593;
      </Button>
    </BackToTopContainer>
  )
}

export default BackToTop
