import React from "react"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { APREMIA_WHATSAPP } from "../params"

import WhatsappIconSrc from "../images/icon-whatsapp.svg"

const Button = styled.a`
  background-color: ${props => props.theme.colors["green"]};
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .16);
  padding: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 45px;
  width: 45px;
  text-align: center;
  &:hover {
    width: 190px;
    border-radius: 25px;
    & > span {
      display: inline-block !important;
    }
  }
`

const WhatsappIcon = styled(WhatsappIconSrc)`
  fill: #ffffff;
  height: 35px;
`

const WhatsappFloatingButton = () => {
  const { t } = useTranslation()

  return (
    <Button href={`whatsapp://send?phone=${APREMIA_WHATSAPP}&abid=${APREMIA_WHATSAPP}`}>
      <WhatsappIcon />
      <span className="d-none text-white ms-1">{t("whatsapp_button")}</span>
    </Button>
  )
}

export default WhatsappFloatingButton
