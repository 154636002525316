import React, { useState, useEffect } from "react"
import { Navbar, Nav, Container, NavDropdown, Offcanvas } from "react-bootstrap"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"

import { useUrlPrefix } from "../../utils"
import {
  APREMIA_EMAIL,
  APREMIA_PHONE,
  APREMIA_PHONE_FORMATTED,
  APREMIA_WHATSAPP,
} from "../../params"

import ApremiaLogoImage from "../../images/logo.svg"
import ApremiaLogoMiniImage from "../../images/logo-mini.svg"
import IconMailImage from "../../images/icon-mail.svg"
import IconCallImage from "../../images/icon-call.svg"
import IconWhatsappImage from "../../images/icon-whatsapp.svg"

const ApremiaLogo = styled.svg`
  height: 32px;
`

const Icon = styled.svg`
  fill: ${props => props.theme.colors["blue"]};
  height: 24px;
`

const Header = props => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint()
  const location = useLocation()
  const urlPrefix = useUrlPrefix()
  const { language, defaultLanguage, changeLanguage } = useI18next()
  const { 
    hideMenu = false,
    disableResponsiveLogo = false,
  } = props
  
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [insuranceMenuActive, setInsuranceMenuActive] = useState(false)

  const closeMenu = () => {
    setVisibleMenu(false)
  }

  const gotoQuoteForm = event => {
    event.preventDefault()
    /* const form = document.getElementById("quote-form")
    if (form !== null) {
      form.scrollIntoView()
      if (visibleMenu) {
        setTimeout(() => {
          setVisibleMenu(false)
        }, 150)
      }
    } else {
      navigate(`${urlPrefix}/#quote-form`)
    } */
    navigate('https://portal.apremia.com/es/quote/about_me');
  }

  useEffect(() => {
    setInsuranceMenuActive(/-insurance\/$/.test(location.pathname))
  }, [location.pathname])

  if (!location) return null;
  return (
    <Navbar
      expand="lg"
      variant="light"
      fixed="top"
      className="py-2 py-lg-0"
      bg="white"
    >
      <Container>
        <Navbar.Brand
          href={language === defaultLanguage ? "/" : `/${language}/`}
        >
          <ApremiaLogo
            as={breakpoints.sm && !disableResponsiveLogo ? ApremiaLogoMiniImage : ApremiaLogoImage}
          />
        </Navbar.Brand>

        {!hideMenu && (
          <Nav className="me-auto d-none d-lg-flex" activeKey={location.pathname}>
            <Nav.Link href={`${urlPrefix}/about-us/`}>{t("about_us")}</Nav.Link>
            <NavDropdown
              title={t("insurances")}
              id="insurances-nav-dropdown"
              active={insuranceMenuActive}
            >
              <NavDropdown.Item href={`${urlPrefix}/medical-insurance/`}>
                {t("medical_insurance")}
              </NavDropdown.Item>
              <NavDropdown.Item href={`${urlPrefix}/dental-insurance/`}>
                {t("dental_insurance")}
              </NavDropdown.Item>
              <NavDropdown.Item href={`${urlPrefix}/vision-insurance/`}>
                {t("vision_insurance")}
              </NavDropdown.Item>
              <NavDropdown.Item href={`${urlPrefix}/accident-insurance/`}>
                {t("accident_insurance")}
              </NavDropdown.Item>
              <NavDropdown.Item href={`${urlPrefix}/obamacare-insurance/`}>
                Obamacare
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link>{t("blog")}</Nav.Link> */}
            <Nav.Link href={`${urlPrefix}/contact-us/`}>
              {t("contact_us")}
            </Nav.Link>
          </Nav>
        )}

        <Nav>
          {/* <NavDropdown
            title={language.toLocaleUpperCase()}
            id="lang-nav-dropdown"
            className="me-4 d-none d-lg-block"
          >
            <NavDropdown.Item
              onClick={e => {
                e.preventDefault()
                changeLanguage("es")
              }}
            >
              Español
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={e => {
                e.preventDefault()
                changeLanguage("en")
              }}
            >
              English
            </NavDropdown.Item>
          </NavDropdown> */}

          <Nav.Link
            className="btn btn-primary px-4 py-1 py-lg-2 align-self-center"
            href="#"
            onClick={gotoQuoteForm}
          >
            {t("get_quote")}
          </Nav.Link>
        </Nav>

        {!hideMenu && (
          <>
            <Navbar.Toggle onClick={() => setVisibleMenu(true)} />
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
              show={visibleMenu}
              onHide={closeMenu}
            >
              <Offcanvas.Header className="border-bottom border-light">
                <Offcanvas.Title id="offcanvasNavbarLabel">
                  <ApremiaLogo as={ApremiaLogoImage} />
                </Offcanvas.Title>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={closeMenu}
                ></button>
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-1">
                <Nav className="main-menu flex-column" onSelect={closeMenu}>
                  <Nav.Link href={`${urlPrefix}/about-us/`}>
                    {t("about_us")}
                  </Nav.Link>
                  <Nav.Link>{t("insurances")}</Nav.Link>
                  <Nav className="flex-column ps-3">
                    <Nav.Link href={`${urlPrefix}/medical-insurance/`}>
                      {t("medical_insurance")}
                    </Nav.Link>
                    <Nav.Link href={`${urlPrefix}/dental-insurance/`}>
                      {t("dental_insurance")}
                    </Nav.Link>
                    <Nav.Link href={`${urlPrefix}/vision-insurance/`}>
                      {t("vision_insurance")}
                    </Nav.Link>
                    <Nav.Link href={`${urlPrefix}/accident-insurance/`}>
                      {t("accident_insurance")}
                    </Nav.Link>
                    <Nav.Link href={`${urlPrefix}/obamacare-insurance/`}>
                      Obamacare
                    </Nav.Link>
                  </Nav>
                  {/* <Nav.Link>{t("blog")}</Nav.Link> */}
                  <Nav.Link href={`${urlPrefix}/contact-us/`}>
                    {t("contact_us")}
                  </Nav.Link>
                  <Nav.Item className="py-3">
                    <div className="contact-menu">
                      <a href={`mailto:${APREMIA_EMAIL}`}>
                        <Icon as={IconMailImage} /> {APREMIA_EMAIL}
                      </a>
                      <a href={`tel:${APREMIA_PHONE}`}>
                        <Icon as={IconCallImage} /> {APREMIA_PHONE_FORMATTED}
                      </a>
                      <a
                        href={`whatsapp://send?phone=${APREMIA_WHATSAPP}&abid=${APREMIA_WHATSAPP}`}
                      >
                        <Icon as={IconWhatsappImage} /> Whatsapp
                      </a>
                    </div>
                  </Nav.Item>
                </Nav>

                <button className="btn btn-primary mt-3" onClick={gotoQuoteForm}>
                  {t("get_quote")}
                </button>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </>
        )}
      </Container>
    </Navbar>
  )
}

export default Header
