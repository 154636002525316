/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import { theme } from "../../theme"
import Header from "./header"
import Footer from "./footer"
import BackToTop from "../backToTopButton"
import WhatsappFloatingButton from "../whatsappFloatingButton"

const Layout = (props) => {
  const {
    children,
    hideNavbarMenu = false,
    disableNavbarMenuResponsiveLogo = false,
    hideFooter = false
  } = props

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header
          hideMenu={hideNavbarMenu}
          disableResponsiveLogo={disableNavbarMenuResponsiveLogo}
        />
        
        <main>{children}</main>
        
        {!hideFooter && <Footer />}
        
        <BackToTop />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
}

export default Layout
